<template>
  <BCard class="mt-2">
    <BRow class=" justify-between">
      <BCol
        class="flex items-center"
        md="4"
      >
        <strong class="text-black text-bold text-xl">Data Member</strong>
      </BCol>
      <BCol
        md="8"
        class="flex justify-end items-center"
        style="gap: 10px;"
      >
        <BButton
          id="chart"
          class="btn-sm d-flex w-50 justify-between items-center"
          variant="outline-secondary"
          style="border-color: #e2e2e2 !important; min-width: 100px; max-width: 100px;"
        >
          <div class="d-flex align-items-center">
            <img
              v-if="isBoostr"
              :src="iconBooster"
              alt="Komship"
              width="100px"
            >
            <img
              v-if="isKomship"
              :src="iconKomship"
              alt="Komship"
              width="100px"
            >
            <img
              v-if="isKompack"
              :src="iconKompack"
              alt="Komship"
              width="100px"
            >
            <img
              v-if="isKomcard"
              :src="iconKomcard"
              alt="Komship"
              width="100px"
            >
          </div>
        </BButton>
        <BPopover
          id="popover-filter-services"
          target="chart"
          placement="bottom"
        >
          <ul style="margin: -8px -10px -8px -10px;">
            <li
              v-for="(value, idx) in platform"
              :key="idx"
            >
              <BButton
                class="d-flex align-items-center h-12"
                style="padding-left: 0px;"
                variant="flat-dark"
                @click="changePlatform(value.name)"
              >
                <img
                  :src="value.image"
                  alt="Komerce"
                  style="width:90px"
                >
              </BButton>
            </li>
          </ul>
        </BPopover>
        <div v-if="isKomship">
          <BFormSelect
            v-model="filter.district"
            :options="filter.districtOptions"
            placeholder="Pilih Status"
            class="w-auto"
            style="height: 32px !important"
          />
        </div>
        <DateRangePicker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          :opens="'left'"
          class="withdrawal-date-range"
          style="min-width: 170px !important"
        >
          <template
            v-slot:input="picker"
            style="min-width: 400px"
          >
            <div class="d-flex justify-content-between align-items-center mx-1">
              <span
                v-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                Hari ini
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last2)
                "
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                2 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)
                "
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(lastDateOfMonth)
                "
                style="color: #11263C !important;text-wrap: nowrap;"
              >
                Bulan ini
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(komshipDate) && YEAR_MONTH_DAY(picker.endDate) ===
                      YEAR_MONTH_DAY(today)"
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                Semua
              </span>
              <span
                v-else
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                {{ DDMMYYYY(picker.startDate) }} - {{ DDMMYYYY(picker.endDate) }}
              </span>
              <img
                src="https://storage.googleapis.com/komerce/assets/illustration/calendar.png"
                alt="Calendar"
                class="h-5 ml-1"
              >
            </div> </template></DateRangePicker>
        <!-- <BFormInput
          v-model="search"
          placeholder="Cari nama atau email member"
          class="w-auto"
          style="height: 32px !important"
          @input="searchData"
        /> -->
        <BInputGroup class="wrapper-search">
          <template #prepend>
            <BInputGroupText style="border-right: 0">
              <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
              />
            </BInputGroupText>
          </template>
          <BFormInput
            v-model="search"
            placeholder="Cari nama atau email afiliator"
            style="border-left: 0"
            class="pl-0 w-[100px]"
            @input="searchData"
          />
        </BInputGroup>
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(id)="value">
          <span class="d-flex justify-content-center">
            {{ value.index + 1 }}
          </span>
        </template>
        <template #cell(member_name)="value">
          {{ value.item.member_name }}
          <br>
          <span class="text-[#828282]">{{ value.item.member_email }}</span>
        </template>
        <template #cell(package_name)="value">
          {{ value.item.package_name }}
          <br>
          <span
            v-if="value.item.package_name != 'Free'"
            class="text-[#E31A1A]"
            style="font-size: 12px"
          >Exp: {{ DAY_MONTH_YEAR(value.item.expired_date) }}</span>
        </template>
        <template #cell(warehouse_address)="value">
          <div
            v-if="serviceName === 'komship'"
            class="cursor-pointer flex items-center"
          >
            <span>{{ value.item.warehouse_address.district_name || '-' }} </span>
            <div v-if="value.item.warehouse_address.district_name">
              <img
                v-b-tooltip.hover.top="
                  `${value.item.warehouse_address.warehouse_address}`
                "
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt="Komerce"
                class="ml-[5px]"
              >
            </div>
          </div>
          <div
            v-if="serviceName === 'kompack'"
            class="cursor-pointer flex items-center"
          >
            <span v-if="value.item.warehouse_address.length > 1">
              <div
                v-for="(item, idx) in value.item.warehouse_address"
                :key="idx"
                class="cursor-pointer flex items-center mb-[5px]"
              >
                <span>{{ item.city_name }}</span>
                <div
                  v-if="value.item.warehouse_address.length > 1"
                  v-b-tooltip.hover.top="
                    generateWarehouseTooltip(item.warehouse)
                  "
                  class="toltip-gudang ml-[5px]"
                >
                  <span>{{ item.warehouse.length }}</span>
                </div>
              </div>
            </span>
            <span v-if="value.item.warehouse_address.length === 1">
              {{ value.item.warehouse_address[0].city_name }}
            </span>
            <div
              v-if="value.item.warehouse_address.length === 1"
              v-b-tooltip.hover.top="
                `${value.item.warehouse_address[0].warehouse}`
              "
              class="toltip-gudang ml-[5px]"
            >
              <span>{{ value.item.warehouse_address.length }}</span>
            </div>
            <div v-if="value.item.warehouse_address < 1">
              -
            </div>
          </div>
        </template>
        <template #cell(bank_info)="value">
          <div v-if="value.item.bank_info.length > 0">
            <div
              v-for="(bank, idx) in value.item.bank_info.slice(0, 1)"
              :key="idx"
            >
              <div
                class="d-flex align-items-center"
                style="justify-content: space-between;"
              >
                <span>{{ bank.bank_account_name }}</span>
                <div
                  v-if="value.item.bank_info.length > 1"
                  v-b-toggle="`collapse-${String(value.index)}`"
                  variant="flat-dark"
                  size="sm"
                >
                  <feather-icon
                    icon="ChevronDownIcon"
                    class="when-closed"
                  />
                  <feather-icon
                    icon="ChevronUpIcon"
                    class="when-opened"
                  />
                </div>
              </div>
              <span class="text-[#828282]">{{ bank.bank_account_no }}</span>
            </div>
            <BCollapse :id="`collapse-${String(value.index)}`">
              <div
                v-for="(bank, idx) in value.item.bank_info.slice(1)"
                :key="idx"
                class="mb-50 mt-[8px]"
              >
                <span>{{ bank.bank_account_name }}</span>
                <br>
                <span class="text-[#828282]">{{ bank.bank_account_no }}</span>
              </div>
            </BCollapse>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </BTable>
    </BOverlay>
    <BRow>
      <BCol
        cols="12"
        class="d-flex justify-content-between"
      >
        <div
          class="bg-light d-flex justify-content-center align-items-center p-50 rounded"
        >
          <span class="text-black mr-50"> List per halaman: </span>
          <BButton
            v-for="page in optionsPage"
            :key="page"
            class="btn-icon"
            size="sm"
            :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
            @click="setPerPage(page)"
          >
            {{ page }}
          </BButton>
        </div>

        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="totalPerPage"
          first-number
          last-number
          class="pagination-primary"
        >
          <template #prev-text>
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </template>
          <template #next-text>
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </template>
        </BPagination>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  DAY_MONTH_YEAR,
  LABELMONTH,
  YEAR_MONTH,
  YEAR_MONTH_DAY,
  DDMMYYYY,
  LABELDAY,
} from '@/libs/filterDate'
import {
  firstDateOfMonth,
  komshipDate,
  last2,
  last7,
  lastDateOfMonth,
  today,
} from '@/store/helpers'
import { IDR } from '@/libs/currency'
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import DateRangePicker from 'vue2-daterange-picker'
import {
  columnMemberAffiliator, columnMemberBooster, columnMemberKompack, columnMemberKomcards,
} from '../config'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    DateRangePicker,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filter: {
        month: moment().format('YYYY-MM'),
        district: null,
        districtOptions: [],
      },
      DAY_MONTH_YEAR,
      YEAR_MONTH_DAY,
      komshipDate,
      DDMMYYYY,
      last2,
      last7,
      firstDateOfMonth,
      lastDateOfMonth,
      today,
      IDR,
      items: [],
      fields: columnMemberAffiliator,
      monthlabel: LABELMONTH,
      loading: false,
      search: '',
      currentPage: 1,
      totalRows: 0,
      optionsPage: [20, 50, 100, 200],
      totalPerPage: 20,
      idAffiliator: this.$router.history.current.params.id,
      isEmpty,
      sortBy: 'join_date',
      sortDesc: true,
      iconKomship:
        'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/komship.png',
      iconBooster:
        'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komplace.png',
      iconKompack:
        'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/kompack.png',
      iconKomcard:
        'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komcards.png',
      serviceName: 'komship',
      isKomship: true,
      isBoostr: false,
      isKompack: false,
      isKomcard: false,
      platform: [
        {
          image:
            'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/komship.png',
          name: 'komship',
        },
        {
          image:
            'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komplace.png',
          name: 'boostr',
        },
        {
          image:
            'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/kompack.png',
          name: 'kompack',
        },
        {
          image:
            'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komcards.png',
          name: 'komcard',
        },
      ],
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      ranges: {
        'Hari ini': [today, today],
        '2 Hari terakhir': [last2, today],
        '7 Hari Terakhir': [last7, today],
        'Bulan ini': [firstDateOfMonth, lastDateOfMonth],
        'Semua ': [komshipDate, today],
      },
    }
  },
  computed: {
    maxDatePicker() {
      return moment().endOf('month')
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.getData()
      },
    },
    'filter.month': {
      handler() {
        this.getData()
      },
    },
    dateRange: {
      handler() {
        this.getData()
      },
    },
    'filter.district': {
      handler() {
        this.getData()
      },
    },
    serviceName: {
      handler(value) {
        if (value === 'komship') {
          this.fields = columnMemberAffiliator
          this.isKomship = true
          this.isBoostr = false
          this.isKompack = false
          this.isKomcard = false
        } else {
          this.fields = columnMemberBooster
          this.isKomship = false
          this.isBoostr = true
          this.isKompack = false
          this.isKomcard = false
        }
        if (value === 'kompack') {
          this.fields = columnMemberKompack
          this.isKomship = false
          this.isBoostr = false
          this.isKompack = true
          this.isKomcard = false
        }
        if (value === 'komcard') {
          this.fields = columnMemberKomcards
          this.isKomship = false
          this.isBoostr = false
          this.isKompack = false
          this.isKomcard = true
        }
      },
    },
  },
  mounted() {
    this.getData()
    this.getDistrict()
  },
  methods: {
    async getData() {
      this.loading = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        service_name: this.serviceName,
        warehouse_address: this.filter.district,
        per_page: this.totalPerPage,
        page: this.currentPage,
        search: this.search,
      }
      const url = `/v1/member/admin/get-affiliator-members/${this.idAffiliator}`
      await affiliateAxiosIns
        .get(url, { params })
        .then(res => {
          this.loading = false
          const { data, total } = res.data.data
          this.items = data
          this.totalRows = total
        })
        .catch(err => {
          const { message } = err.response.data
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: message,
                variant: 'danger',
              },
            },
            { timeout: 1000 },
          )
          this.loading = false
        })
    },
    generateWarehouseTooltip(val) {
      const tooltipText = val.join('\n')
      return tooltipText
    },
    async getDistrict() {
      const url = `/v1/member/admin/${this.idAffiliator}/list-district`
      await affiliateAxiosIns
        .get(url)
        .then(res => {
          const { district_name } = res.data.data
          const defaultWarehouse = [{ value: null, text: 'Alamat Gudang' }]
          if (!isEmpty(district_name)) {
            const districtData = district_name.map(district => ({
              text: district,
              value: district,
            }))
            this.filter.districtOptions = defaultWarehouse.concat(districtData)
          } else {
            this.filter.districtOptions = defaultWarehouse
          }
        })
        .catch(err => {
          const { message } = err.response.data
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: message,
                variant: 'danger',
              },
            },
            { timeout: 1000 },
          )
        })
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getData()
    },
    searchData: _.debounce(function search() {
      this.getData()
    }, 1000),
    changePlatform(params) {
      if (params === 'komship') {
        this.serviceName = 'komship'
        this.isKomship = true
        this.isBoostr = false
        this.isKompack = false
        this.isKomcard = false
        this.getData()
      }
      if (params === 'boostr') {
        this.serviceName = 'boostr'
        this.isKomship = false
        this.isBoostr = true
        this.isKompack = false
        this.isKomcard = false
        this.getData()
      }
      if (params === 'kompack') {
        this.serviceName = 'kompack'
        this.isKomship = false
        this.isBoostr = false
        this.isKompack = true
        this.isKomcard = false
        this.getData()
      }
      if (params === 'komcard') {
        this.serviceName = 'komcard'
        this.isKomship = false
        this.isBoostr = false
        this.isKompack = false
        this.isKomcard = true
        this.getData()
      }
      this.$root.$emit('bv::hide::popover', 'popover-filter-services')
    },
  },
}
</script>
<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.toltip-gudang {
  display: flex;
  width: 15px;
  height: 15px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  color: white;

  border-radius: 10px;
  background: var(--Kompack-Main, #4285f4);
}
</style>
