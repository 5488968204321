<template>
  <BOverlay
    :show="loading"
    spinner-variant="primary"
    variant="light"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <BCard class="mt-2">
      <BRow class="d-flex">
        <BCol md="6">
          <strong
            class="text-black font-bold text-xl"
          >Profil Affiliator</strong>
        </BCol>
        <BCol
          class="text-center flex justify-end"
          md="6"
        >
          <BButton
            v-if="data.is_pumkm === 0 || data.is_pumkm === -1"
            id="popoverService"
            variant="primary"
            size="sm"
            class="rounded-md mr-2"
            @click="openModalAdd"
          >
            Verifikasi Pendamping UMKM
          </BButton>
        </BCol>
      </BRow>
      <BRow class="mt-2">
        <BCol
          lg="2"
          class="pr-0"
          style="max-width: 140px"
        >
          <BAvatar
            size="6rem"
            class="ml-1"
            :src="data.photo_url"
          />
        </BCol>
        <BCol
          lg="10"
          class="pr-0 mt-[5px]"
        >
          <BRow class="my-[5px] items-center">
            <BCol
              class="flex align-items-center font-medium text-black text-bold text-xl"
            >
              {{ data.affiliator_name }}
              <BButton
                v-if="data.is_pumkm === 1"
                id="popoverBadge"
                class="btn-sm d-flex justify-between items-center ml-[4px]"
                aria-expanded="false"
                variant="outline-secondary"
                style="
                  border-color: transparent !important;
                  padding: 0 !important;
                "
                @click="openPO"
              >
                <img
                  id="popoverBadge"
                  src="https://storage.googleapis.com/komerce/assets/svg/Badge_PUMKM.svg"
                  alt="Pendamping UMKM"
                  width="25px"
                >
              </BButton>
              <BPopover
                id="popover-badge"
                ref="popoverBadge"
                target="popoverBadge"
                :show="pop"
                placement="top"
                :triggers="triggers"
              >
                <ul
                  style="
                    margin: -10px -18px -8px -18px;
                    background-color: #24292f;
                    border-radius: 3px;
                  "
                >
                  <li>
                    <BButton
                      class="d-flex w-100 align-items-center pl-1"
                      variant="flat-dark"
                      style="border-color: transparent !important; height: 31px"
                      @click="openModalEdit"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                        alt="edit"
                        width="16px"
                      >
                      <span
                        class="ms-2 capitalize text-white text-align-start"
                        style="font-size: 12px"
                      >Edit</span></BButton>
                  </li>
                  <li>
                    <BButton
                      class="d-flex w-100 align-items-center pl-1"
                      variant="flat-dark"
                      style="border-color: transparent !important; height: 31px"
                      @click="openModalHapus"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                        alt="edit"
                        width="16px"
                      >
                      <span
                        class="ms-2 capitalize text-white text-align-start"
                        style="font-size: 12px"
                      >Hapus</span></BButton>
                  </li>
                </ul>
              </BPopover>
            </BCol>
          </BRow>
          <BRow class="my-[5px] items-center">
            <BCol class="text-[#626262] font-medium">
              {{
                !isEmpty(data.affiliator_address)
                  ? data.affiliator_address
                  : 'Alamat affiliator belum ada'
              }}
            </BCol>
          </BRow>
          <BRow class="my-[5px] items-center">
            <BCol
              class="text-[#626262] font-medium"
              md="3"
            >
              Tanggal Bergabung
            </BCol>
            <BCol> : {{ DAY_MONTH_YEAR(data.join_date) }} </BCol>
          </BRow>
          <BRow class="my-[5px] items-center">
            <BCol
              class="text-[#626262] font-medium"
              md="3"
            >
              Alamat Gudang
            </BCol>
            <BCol>
              :
              {{
                !isEmpty(data.warehouse_address)
                  ? data.warehouse_address
                  : 'Belum ada alamat gudang'
              }}
            </BCol>
          </BRow>
          <BRow class="items-center">
            <BCol
              class="text-[#626262] font-medium"
              md="3"
            >
              Pengguna dari
            </BCol>
            <BCol class="flex items-center flex-row gap-4">
              :<BButton
                v-if="data.is_komship === 1"
                size="sm"
                variant="outline-secondary"
                class="d-flex items-center"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/logo/icon-komship.svg"
                  alt="Komerce"
                  class="w-[20px] me-2"
                >
                <span class="text-black text-bold text-[14px] font-[600]">Komship</span>
              </BButton>
              <BButton
                v-if="data.is_boostr === 1"
                size="sm"
                variant="outline-secondary"
                class="d-flex items-center"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/logo/Logo-Komplace-main.svg"
                  alt="Komerce"
                  class="w-[20px] me-2"
                >
                <span class="text-black text-bold text-[14px] font-[600]">Komplace</span>
              </BButton>
              <BButton
                v-if="data.is_kompack === 1"
                size="sm"
                variant="outline-secondary"
                class="d-flex items-center"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                  alt="Komerce"
                  class="w-[20px] me-2"
                >
                <span class="text-black text-bold text-[14px] font-[600]">Kompack</span>
              </BButton>
              <BButton
                v-if="data.is_komcard === 1"
                size="sm"
                variant="outline-secondary"
                class="d-flex items-center"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-komcards.svg"
                  alt="Komerce"
                  class="w-[20px] mr-[5px]"
                >
                <span class="text-black text-bold text-[14px] font-semibold">Komcards</span>
              </BButton>

            </BCol>
          </BRow>
          <BRow class="mt-2">
            <BCol
              lg="3"
              class="pr-0"
            >
              <CardUI
                :title="'Total Member'"
                :loading="loading"
                :count="IDRWithoutLbl(data.total_member)"
                :border-class="'totalMember'"
                :icon="false"
              />
            </BCol>
            <BCol
              lg="3"
              class="pr-0"
            >
              <CardUI
                :title="'Total Komisi'"
                :loading="loading"
                :count="IDR(data.total_commission)"
                :border-class="'totalComission'"
                :icon="false"
              />
            </BCol>
            <BCol
              lg="3"
              class="pr-0"
            >
              <CardUI
                :title="'Komisi Cair'"
                :loading="loading"
                :count="IDR(data.total_withdrawal_commission)"
                :border-class="'comissionCair'"
                :icon="false"
              />
            </BCol>
            <BCol
              lg="3"
              class="pr-0"
            >
              <CardUI
                :title="'Sisa Komisi'"
                :loading="loading"
                :count="IDR(data.total_remaining_commission)"
                :border-class="'comissionSisa'"
                :icon="false"
              />
            </BCol>
          </BRow>
        </BCol>
        <BModal
          id="form-modal"
          ref="formModal"
          :hide-footer="true"
          :hide-header="true"
          centered
          @show="resetModal"
          @hidden="resetModal"
        >
          <div class="d-flex justify-content-center mt-2">
            <img
              src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
              alt=""
              width="80px"
            >
          </div>
          <div class="d-flex flex-row justify-content-center mt-1">
            <strong
              v-if="isAdd"
              class="text-black font-bold text-xl"
            >Lengkapi Form Pendukung Pendamping UMKM</strong>
            <strong
              v-if="isEdit"
              class="text-black font-bold text-xl"
            >Update Form Pendukung Pendamping UMKM</strong>
          </div>
          <div class="d-flex flex-row justify-content-center mt-1 mb-4">
            <span
              v-if="isAdd"
              style="text-align: center"
            >Lengkapi form pendukung untuk mengonfirmasi bahwa afiliator
              tersebut merupakan Pendamping UMKM</span>
            <span
              v-if="isEdit"
              style="text-align: center"
            >Update form pendukung untuk mengonfirmasi bahwa afiliator
              tersebut merupakan Pendamping UMKM</span>
          </div>
          <b-form @submit.stop.prevent="submit">
            <div>
              <span
                class="text-black"
                style="font-size: 12px"
              >Link Bio</span>
              <b-input-group>
                <b-form-input
                  id="input-link"
                  v-model="fillForm.link_pumkm"
                  type="url"
                  label="Link Bio"
                  :placeholder="
                    fillForm.link_pumkm === ''
                      ? 'Letakkan link di sini'
                      : fillForm.link_pumkm
                  "
                  style="border-right: none"
                  @input="validateUrl"
                />
                <b-input-group-append
                  class="d-flex align-items-center link-pumkm"
                >
                  <span
                    style="color: #cbcbcb; font-size: 15px;"
                  >|</span>
                  <BButton
                    class="button-link"
                    :disabled="LinkDisabled"
                    @click="handleLink()"
                  >
                    <span
                      class="font-[12px]"
                      :style="styled"
                    >Link</span>
                  </BButton>
                </b-input-group-append>
              </b-input-group>
              <span
                v-if="!isUrlValid"
                style="color: #f95031; font-size: 10px"
              >{{ error }}</span>
            </div>
            <div class="mt-2">
              <span
                class="text-black"
                style="font-size: 12px"
              >Kota/Kabupaten</span>
              <v-select
                v-model="fillForm.city"
                :value="fillForm.city"
                :options="options"
                :placeholder="fillForm.city ? fillForm.city : (fillForm.city && fillForm.city.label) || 'Pilih Kota'"
                class="form-address"
                @search="debounceCity"
              >
                <template #no-options>
                  Kota yang kamu cari tidak ada
                </template>
              </v-select>
            </div>
            <div class="mt-2">
              <span
                class="text-black"
                style="font-size: 12px"
              >Keahlian</span>
              <b-input-group>
                <b-form-input
                  v-model="fillForm.skill_set"
                  type="text"
                  :placeholder="fillForm.skill_set"
                />
              </b-input-group>
            </div>
            <div class="mt-2">
              <span
                class="text-black"
                style="font-size: 12px"
              >Foto</span>
              <b-input-group>
                <b-input-group-append
                  class="d-flex align-items-center w-100"
                  style="
                    border-width: 1px;
                    border-radius: 5px;
                    border-color: #cfcfcf;
                  "
                  @click="$refs.photoFile.click()"
                >
                  <BButton
                    class="d-flex align-items-center ml-[4px] w-40 btn-upload"
                    variant="warning"
                    size="sm"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-upload.svg"
                      alt=""
                      width="15px"
                    >
                    <span
                      class="ml-[2px]"
                      style="font-size: 12px"
                    >
                      Pilih Foto</span>
                  </BButton>
                  <b-form-input
                    v-model="photo_upload"
                    class="text-file-foto"
                    :readonly="true"
                    :placeholder="photo_upload === null? fillForm.name_photo: photo_upload"
                  />
                </b-input-group-append>
                <input
                  id="photoFile"
                  ref="photoFile"
                  class="form-control"
                  type="file"
                  style="display: none"
                  @change="validateExt"
                >
              </b-input-group>
              <span
                res="err-mess"
                class="text-black err-ok"
              >*Upload foto dengan maksimal 1 Mb</span>
            </div>
            <div class="mt-2">
              <span
                class="text-black"
                style="font-size: 12px"
              >Sosial Media</span>
              <div class="row">
                <div class="d-flex col-6">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/svg/Outline-Facebook.svg"
                    style="margin-right: 5px"
                  >
                  <b-input-group>
                    <b-form-input
                      v-model="fillForm.fb_account"
                      type="text"
                      :placeholder="
                        fillForm.fb_account === ''
                          ? 'Facebook'
                          : fillForm.fb_account
                      "
                    />
                  </b-input-group>
                </div>
                <div class="d-flex col">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/svg/Outline-Twitter.svg"
                    style="margin-right: 5px"
                  >
                  <b-input-group>
                    <b-form-input
                      v-model="fillForm.tw_account"
                      type="text"
                      :placeholder="
                        fillForm.tw_account === ''
                          ? 'Twitter'
                          : fillForm.tw_account
                      "
                    />
                  </b-input-group>
                </div>
              </div>
              <div class="row mt-1">
                <div class="d-flex col-6">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/svg/Outline-Instagram.svg"
                    style="margin-right: 5px"
                  >
                  <b-input-group>
                    <b-form-input
                      v-model="fillForm.ig_account"
                      type="text"
                      :placeholder="
                        fillForm.ig_account === ''
                          ? 'Instagram'
                          : fillForm.ig_account
                      "
                    />
                  </b-input-group>
                </div>
                <div class="d-flex col">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/svg/Outline-Telegram.svg"
                    style="margin-right: 5px"
                  >
                  <b-input-group>
                    <b-form-input
                      v-model="fillForm.tg_account"
                      type="text"
                      :placeholder="
                        fillForm.tg_account === ''
                          ? 'Telegram'
                          : fillForm.tg_account
                      "
                    />
                  </b-input-group>
                </div>
              </div>
              <div class="row mt-1">
                <div class="d-flex col-md-6">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/svg/Outline-tiktok.svg"
                    style="margin-right: 1px"
                  >
                  <b-input-group>
                    <b-form-input
                      v-model="fillForm.tk_account"
                      type="text"
                      :placeholder="
                        fillForm.tk_account === ''
                          ? 'Tiktok'
                          : fillForm.tk_account
                      "
                    />
                  </b-input-group>
                </div>
              </div>
              <span style="font-size: 9px; color: red">{{ err_socmed }}</span>
            </div>
          </b-form>
          <div class="d-flex gap-8">
            <b-button
              class="d-block mt-3 mb-2 w-50"
              style="border-radius: 8px !important"
              variant="outline-primary"
              @click="hideModal"
            >
              <div class="text-light" />
              Batal
            </b-button>
            <b-button
              type="submit"
              class="d-block mt-3 mb-2 w-50"
              style="border-radius: 8px !important"
              :variant="
                fillForm.link_pumkm === '' ||
                  !isUrlValid ||
                  fillForm.city === '' ||
                  fillForm.skill_set === ''
                  ? 'secondary'
                  : 'primary' || !isSocMedValid
              "
              :style="
                fillForm.link_pumkm === '' ||
                  !isUrlValid ||
                  fillForm.city === '' ||
                  fillForm.skill_set === ''
                  ? 'cursor: not-allowed;'
                  : '' || !isSocMedValid
              "
              :disabled="
                fillForm.link_pumkm === '' ||
                  !isUrlValid ||
                  fillForm.city === '' ||
                  fillForm.skill_set === '' ||
                  !isSocMedValid || isPhotoOke
              "
              @click.prevent="submit()"
            >
              <div
                v-if="isAdd"
                class="text-light"
              >
                <b-spinner
                  v-if="loadingSubmit"
                  small
                />
                Konfirmasi
              </div>
              <div
                v-if="isEdit"
                class="text-light"
              >
                <b-spinner
                  v-if="loadingSubmit"
                  small
                />
                Update
              </div>
            </b-button>
          </div>
        </BModal>
        <BModal
          id="form-modal-hapus"
          ref="formModalHapus"
          :hide-footer="true"
          :hide-header="true"
          centered
          @show="resetModal"
          @hidden="resetModal"
        >
          <div class="d-flex justify-content-center mt-2">
            <img
              src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
              alt=""
              width="80px"
            >
          </div>
          <div class="d-flex flex-row justify-content-center mt-1">
            <strong
              class="text-black font-bold text-xl"
            >Hapus badge Pendamping UMKM?</strong>
          </div>
          <div class="d-flex flex-row justify-content-center mt-1 mb-1">
            <span
              style="text-align: center"
            >Apakah kamu yakin akan menghapus badge Pendamping UMKM dari user
              ini?</span>
          </div>
          <div class="d-flex gap-8">
            <b-button
              class="d-block mt-2 mb-2 w-50"
              style="border-radius: 8px !important"
              variant="outline-primary"
              @click="hideModalHapus"
            >
              <div class="text-light" />
              Batal
            </b-button>
            <b-button
              type="submit"
              class="d-block mt-2 mb-2 w-50"
              style="border-radius: 8px !important"
              variant="primary"
              @click="deleteBadge"
            >
              <div class="text-light" />
              Hapus
            </b-button>
          </div>
        </BModal>
      </BRow>
    </BCard>
  </BOverlay>
</template>

<script>
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { IDRWithoutLbl, IDR } from '@/libs/currency'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { affiliateAxiosIns } from '@/libs/axios'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { BPopover } from 'bootstrap-vue'
import CardUI from '../CardsUI.vue'

export default {
  components: {
    CardUI,
    BPopover,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      IDRWithoutLbl,
      IDR,
      isEmpty,
      isUrlValid: true,
      error: 'Silakan masukkan link yang valid',
      pop: false,
      loadingSubmit: false,
      photo_upload: null,
      address_city: null,
      options: [],
      fillForm: [],
      debounceTimeout: null,
      err_socmed: '',
      isSocMedValid: false,
      isAdd: false,
      isEdit: false,
      isPhotoOke: false,
      link_pumkm: '',
      triggers: 'focus',
    }
  },

  computed: {
    LinkDisabled() {
      return this.fillForm.link_pumkm === '' || !this.isUrlValid
    },
    styled() {
      return this.fillForm.link_pumkm === '' || !this.isUrlValid ? 'color: #cbcbcb' : 'color:#f95031'
    },
  },
  watch: {
    'fillForm.fb_account': 'validateSocialMedia',
    'fillForm.tw_account': 'validateSocialMedia',
    'fillForm.ig_account': 'validateSocialMedia',
    'fillForm.tg_account': 'validateSocialMedia',
    'fillForm.tk_account': 'validateSocialMedia',
  },
  mounted() {
    this.getFillForm()
    this.toggleTriggers()
  },

  methods: {

    handleLink() {
      window.open(this.fillForm.link_pumkm, '_blank')
    },
    openModalAdd() {
      this.getFillForm()
      if (this.data.registered_pumkm === 1) {
        this.$bvModal.show('form-modal')
      } else {
        this.$toast(
          {
            component: ToastificationContentVue,
            props: {
              title: 'Galat',
              text: 'Affiliator belum mendaftar Pendamping UMKM',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          },
          { timeout: 3000 },
        )
      }
      this.isAdd = true
      this.isEdit = false
    },
    openModalEdit() {
      this.getFillForm()
      this.isEdit = true
      this.isAdd = false
      this.pop = false
      this.$bvModal.show('form-modal')
      this.$bvModal.hide('form-modal-hapus')
    },
    openModalHapus() {
      this.pop = false
      this.$bvModal.show('form-modal-hapus')
      this.$bvModal.hide('form-modal')
    },
    hideModal() {
      this.fillForm.link_pumkm = ''
      this.err_socmed = ''
      this.$refs.formModal.hide()
    },
    hideModalHapus() {
      this.$refs.formModalHapus.hide()
    },
    validateUrl() {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/

      this.isUrlValid = urlPattern.test(this.fillForm.link_pumkm)
    },
    debounceCity(value) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.getCity(value)
      }, 1000)
    },
    async getCity(value) {
      const url = '/v1/register/search-city'

      await affiliateAxiosIns
        .get(url, {
          params: {
            search: value,
          },
        })
        .then(res => {
          const { data } = res.data
          this.options = data.map(city => ({
            label: `${city.city_name}, ${city.province_name}`,
            value: city.city_name,
          }))
        })
    },
    validateExt(evChange) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-err')
          errorSpan.classList.add('err-ok')
        }
        this.$refs.photoFile.files = null
        this.photo_upload = null
      } else {
        this.validateSize()
      }
    },
    validateSize() {
      // in bytes
      const maxSizeInBytes = 1024 * 1024

      const fileSize = this.$refs.photoFile.files[0]?.size || 0
      if (fileSize > maxSizeInBytes) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'File tidak boleh lebih dari 1Mb',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-ok')
          errorSpan.classList.add('err-err')
        }
        this.$refs.photoFile.files = null
        this.photo_upload = null
        this.isPhotoOke = true
        return false
      }
      const errorSpan = document.querySelector('span[res="err-mess"]')
      if (errorSpan) {
        errorSpan.classList.remove('err-err')
        errorSpan.classList.add('err-ok')
      }
      this.onFileInputChange()
      return true
    },
    onFileInputChange() {
      const fileInput = this.$refs.photoFile.files
      if (fileInput.length > 0) {
        this.photo_upload = fileInput[0].name
        this.isPhotoOke = false
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-err')
          errorSpan.classList.add('err-ok')
        }
      } else {
        this.$refs.photoFile.files = null
        this.photo_upload = null
        this.isPhotoOke = true
      }
    },
    resetModal() {
      this.isUrlValid = true
    },
    validateSocialMedia() {
      const filledAccounts = [
        this.fillForm.fb_account,
        this.fillForm.tw_account,
        this.fillForm.ig_account,
        this.fillForm.tg_account,
        this.fillForm.tk_account,
      ]

      const isAnyAccountFilled = filledAccounts.some(account => account !== '')
      this.err_socmed = isAnyAccountFilled
        ? ''
        : '*Setidaknya isi Satu Sosial Media'
      this.isSocMedValid = isAnyAccountFilled
    },

    async submit() {
      this.validateSocialMedia()
      const affiliatorId = parseInt(this.$route.params.id, 10)
      const url = '/v1/affiliator/admin/verify-pumkm'

      const kota = JSON.parse(localStorage.getItem('Kota'))

      const formData = new FormData()
      formData.append('affiliator_id', affiliatorId)
      formData.append('full_name', this.data.affiliator_name)
      formData.append('link', this.fillForm.link_pumkm)
      formData.append('city', this.fillForm.city === kota ? kota : this.fillForm.city.label)
      formData.append(
        'photo',
        this.$refs.photoFile.files[0] === undefined
          ? ''
          : this.$refs.photoFile.files[0],
      )
      formData.append('skill', this.fillForm.skill_set)
      formData.append('fb', this.fillForm.fb_account)
      formData.append('tw', this.fillForm.tw_account)
      formData.append('ig', this.fillForm.ig_account)
      formData.append('tg', this.fillForm.tg_account)
      formData.append('ttk', this.fillForm.tk_account)

      this.loadingSubmit = true

      await affiliateAxiosIns
        .post(url, formData)
        .then(() => {
          if (this.isAdd === true) {
            this.$toast(
              {
                component: ToastificationContentVue,
                props: {
                  title: 'Sukses',
                  icon: 'CheckIcon',
                  text: 'Status Pendamping UMKM disetujui',
                  variant: 'success',
                },
              },
              { timeout: 3000 },
            )
          } if (this.isEdit === true) {
            this.$toast(
              {
                component: ToastificationContentVue,
                props: {
                  title: 'Sukses',
                  icon: 'CheckIcon',
                  text: 'Update Form Pendukung Pendamping UMKM Berhasil Di-Update',
                  variant: 'success',
                },
              },
              { timeout: 3000 },
            )
          }

          this.$refs.formModal.hide()
          this.loadingSubmit = false
          window.location.reload()
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            { timeout: 3000 },
          )
          this.loadingSubmit = false
        })
    },
    async deleteBadge() {
      const affiliatorId = parseInt(this.$route.params.id, 10)
      const url = `/v1/affiliator/admin/removal-pumkm/${affiliatorId}`

      await affiliateAxiosIns
        .delete(url)
        .then(res => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Lencana Pendamping UMKM Berhasil Dihapus',
                variant: 'success',
              },
            },
            { timeout: 3000 },
          )
          this.$refs.formModalHapus.hide()
          window.location.reload()
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            { timeout: 3000 },
          )
        })
    },
    openPO() {
      this.toggleTriggers()
      this.pop = !this.pop
    },
    async getFillForm() {
      const affiliatorId = parseInt(this.$route.params.id, 10)
      const url = `/v1/affiliator/admin/check-register-pumkm/${affiliatorId}`

      await affiliateAxiosIns.get(url).then(res => {
        this.fillForm = res.data.data
        localStorage.setItem('Kota', JSON.stringify(this.fillForm.city))
      })
    },
    toggleTriggers() {
      this.triggers = this.triggers === 'focus' ? 'hover' : 'focus'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '../Dashboard.scss';

.err-ok {
  font-size: 10px;
  color: grey;
}

.err-err {
  font-size: 10px;
  color: red;
}

</style>
