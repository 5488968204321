<template>
  <div>
    <BRow class="items-center">
      <img
        src="@/assets/images/icons/back.svg"
        alt="Komerce"
        class="cursor-pointer mx-1"
        @click="$router.back()"
      >
      <strong class="text-black text-bold text-2xl">Dashboard Affiliate</strong>
    </BRow>
    <Info
      :data="data"
      :loading="loading"
    />
    <Chart />
    <List :data="data" />
  </div>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import { affiliateAxiosIns } from '@/libs/axios'
import List from './List.vue'
import Chart from './Chart.vue'
import Info from './Info.vue'

export default {
  components: {
    BRow, List, Chart, Info,
  },
  data() {
    return {
      affiliatorId: this.$router.history.current.params.id,
      data: {},
      loading: false,
    }
  },
  mounted() {
    this.getDataDetailInfo()
  },
  methods: {
    async getDataDetailInfo() {
      this.loading = true
      const url = `/v1/affiliator/admin/detail-info/${this.affiliatorId}`
      await affiliateAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data = data
          this.loading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../Dashboard.scss';
</style>
